module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VRRP1MN0HV"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rava.vn - Giải pháp văn phòng tự động hóa thông minh được tăng cường bởi trí tuệ nhân tạo","short_name":"Rava.vn","start_url":"/","icon":"static/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/www/rava.vn/src/intl","languages":["vi","en"],"defaultLanguage":"vi"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
